import React from 'react';
import {Link} from 'gatsby'
import VectorShape11 from '../WeServe/vector-shape11.png'

const Raionebi = () => {
    return (
        <section className="about-area ptb-10">

        {/* <div className="circle-shape1">
            <img src={shape1} alt="banner" />
        </div> */}

        <div className="container">
            <div className="about-inner-area">
            <h1>Insurance requirements by state</h1>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="about-text">
                            
                            {/* <p>Real innovations.</p> */}
                            
                            <ul className="features-list">
                            <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-alabama/" className="learn-more-btn">
                                        Alabama (AL)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-alaska/" className="learn-more-btn">
                                        Alaska (AK)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-arizona/" className="learn-more-btn">
                                    Arizona (AZ)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-arkansas/" className="learn-more-btn">
                                    Arkansas (AR)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-california/" className="learn-more-btn">
                                    California (CA)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-colorado/" className="learn-more-btn">
                                    Colorado (CO)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-connecticut/" className="learn-more-btn">
                                    Connecticut (CT)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-delaware/" className="learn-more-btn">
                                    Delaware (DE)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-florida/" className="learn-more-btn">
                                    Florida (FL) 
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-georgia/" className="learn-more-btn">
                                    Georgia (GA)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-hawaii/" className="learn-more-btn">
                                    Hawaii (HI)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-idaho/" className="learn-more-btn">
                                    Idaho (ID)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-illinois/" className="learn-more-btn">
                                    Illinois (IL) 
                                    </Link>
                                </li>



                            </ul>


                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="about-text">
                            {/* <h3>Our Mission</h3>
                            <p>Real innovations and a positive customer experience are the heart of successful communication.</p> */}
                            
                            <ul className="features-list">

                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-indiana/" className="learn-more-btn">
                                    Indiana (IN)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-iowa/" className="learn-more-btn">
                                    Iowa (IA)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-kansas/" className="learn-more-btn">
                                    Kansas (KS)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-kentucky/" className="learn-more-btn">
                                    Kentucky (KY)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-louisiana/" className="learn-more-btn">
                                    Louisiana (LA)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-maine/" className="learn-more-btn">
                                    Maine (ME)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-maryland/" className="learn-more-btn">
                                    Maryland (MD)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-massachusetts/" className="learn-more-btn">
                                    Massachusetts (MA)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-michigan/" className="learn-more-btn">
                                    Michigan (MI)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-minnesota/" className="learn-more-btn">
                                    Minnesota (MN)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-mississippi/" className="learn-more-btn">
                                    Mississippi (MS)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-missouri/" className="learn-more-btn">
                                    Missouri (MO)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-montana/" className="learn-more-btn">
                                    Montana (MT)
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="about-text">
                            {/* <h3>Who we are</h3>
                            <p>Real innovations and a positive customer experience are the heart of successful communication.</p> */}
                            
                            <ul className="features-list">


                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-nebraska/" className="learn-more-btn">
                                    Nebraska (NE)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-nevada/" className="learn-more-btn">
                                    Nevada (NV)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-new-hampshire/" className="learn-more-btn">
                                    New Hampshire (NH)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-new-jersey/" className="learn-more-btn">
                                    New Jersey (NJ) 
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-new-mexico/" className="learn-more-btn">
                                    New Mexico (NM)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-north-carolina/" className="learn-more-btn">
                                    North Carolina (NC)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-north-dakota/" className="learn-more-btn">
                                    North Dakota (ND)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-new-york/" className="learn-more-btn">
                                    New York (NY)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-ohio/" className="learn-more-btn">
                                    Ohio (OH)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-oklahoma/" className="learn-more-btn">
                                    Oklahoma (OK)
                                    </Link>
                                </li>

                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-oregon/" className="learn-more-btn">
                                    Oregon (OR)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-pennsylvania/" className="learn-more-btn">
                                    Pennsylvania (PA)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-puerto-rico/" className="learn-more-btn">
                                    Puerto Rico (PR)
                                    </Link>
                                </li>


                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div className="about-text">
                            {/* <h3>Who we are</h3>
                            <p>Real innovations and a positive customer experience are the heart of successful communication.</p> */}
                            
                            <ul className="features-list">

                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-rhode-island/" className="learn-more-btn">
                                    Rhode Island (RI)
                                    </Link>
                                </li>

                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-south-carolina/" className="learn-more-btn">
                                    South Carolina (SC)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-south-dakota/" className="learn-more-btn">
                                    South Dakota (SD)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-tennessee/" className="learn-more-btn">
                                    Tennessee (TN)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-texas/" className="learn-more-btn">
                                    Texas (TX)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-utah/" className="learn-more-btn">
                                    Utah (UT)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-vermont/" className="learn-more-btn">
                                    Vermont (VT)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-virginia/" className="learn-more-btn">
                                    Virginia (VA)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-washington/" className="learn-more-btn">
                                    Washington (WA) 
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-west-virginia/" className="learn-more-btn">
                                    West Virginia (WV)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-wisconsin/" className="learn-more-btn">
                                    Wisconsin (WI)
                                    </Link>
                                </li>
                                <li><i className="flaticon-right"> </i>
                                    <Link to="/smb-insurance-wyoming/" className="learn-more-btn">
                                    Wyoming (WY)
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* <div className="circle-shape1">
            <img src={shape1} alt="banner" />
        </div> */}
    </section>
    )
}

export default Raionebi;